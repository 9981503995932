<template>
  <div class="restaurant-root">
    <div v-if="isSuperAdmin" class="d-flex align-center mb-6">
      <div class="arrow-left-wrap mr-8" @click="goBack">
        <v-img
          max-width="8"
          :src="require('../../assets/img/ArrowLeft.svg')"
        ></v-img>
      </div>
      <span class="text-h5 primaryA--text font-weight-bold">{{
        $t("adminRestaurant.goBackToRestaurants")
      }}</span>
    </div>
    <v-card elevation="2" class="pa-6">
      <div class="d-flex align-center mb-6">
        <span class="text-h4">{{
          $t("adminRestaurant.adminRestaurantName", {
            name: restaurant && restaurant.name,
          })
        }}</span>
      </div>
      <div
        class="d-flex justify-space-between"
        :class="{ flexDirectionColumnMobile: $isMobile }"
      >
        <div class="restaurant-info" :class="{ width100Mobile: $isMobile }">
          <span class="primaryDark--text font-weight-bold">
            {{ $t("adminRestaurant.dataRestaurant") }}:</span
          >
          <div style="width:100%" class="mt-6">
            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.pib") }}</span>
              <span>{{ restaurant.pib }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>
            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.restaurantBusinessName") }}</span>
              <span>{{ restaurant.bussinessName }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>

            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.restaurantName") }}</span>
              <span>{{ restaurant.name }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>
            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.phone") }}</span>
              <span>{{ getPhoneNumber(restaurant.phoneNumber) }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>

            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.email") }}</span>
              <span>{{ restaurant.email }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>
            <div class="secondaryDark2--text d-flex justify-space-between">
              <span>{{ $t("common.restaurantAddress") }}</span>
              <span>{{ restaurant.address }}</span>
            </div>
            <v-divider class="mb-3"></v-divider>
          </div>
        </div>
        <div class="restaurant-stats d-flex flex-wrap justify-start">
          <stats-card
            :title="
              restaurant && restaurant.suppliers && restaurant.suppliers.length
            "
            :subtitle="$t('adminRestaurant.addedSuppliers')"
          >
          </stats-card>
          <stats-card
            :title="allUsers && allUsers.length"
            :subtitle="$t('adminRestaurant.totalUsers')"
          >
          </stats-card>
          <stats-card
            :title="numberOfOrders"
            :subtitle="$t('adminRestaurant.totalOrders')"
          >
          </stats-card>
          <!-- <stats-card
            title="0 RSD"
            :subtitle="$t('adminRestaurant.monthlyIncome')"
          >
          </stats-card> -->
        </div>
      </div>
      <div class="mt-4">
        <v-progress-circular
          v-if="loadingSubscriptions"
          indeterminate
          color="accent"
        ></v-progress-circular>
        <div v-else class="d-flex align-center flex-wrap">
          <div class="d-flex flex-column">
            <div class="maxWidth200">
              <v-select
                v-if="isSuperAdmin && isChangePlan"
                :value="subscriptionPlan"
                :items="subscriptions"
                :label="$t('subscriptions.setSubscription')"
                item-text="label"
                item-value="value"
                @change="onPlanChange"
              ></v-select>
              <div v-else>
                {{ $t("subscriptions.setSubscription") }}:
                <v-chip color="green" outlined>
                  {{
                    subscriptionPlan
                      ? subscriptionPlan.label
                      : $t("subscriptions.planNotExist")
                  }}</v-chip
                >
              </div>
            </div>
          </div>
          <div v-if="isSuperAdmin">
            <v-btn
              v-if="!isChangePlan"
              color="primary"
              class="ml-3 mr-4"
              small
              @click="onChangePlanToggle"
              >{{ $t("subscriptions.changePlan") }}</v-btn
            >
            <div v-else>
              <v-btn
                color="primary"
                class="ml-3"
                small
                :disabled="isSavePlanChangeDisabled"
                @click="onSavePlanConfirm"
                >{{ $t("common.save") }}</v-btn
              >
              <v-btn
                color="secondary"
                class="ml-3 mr-4"
                small
                @click="onSavePlanCancel"
                >{{ $t("common.cancel") }}</v-btn
              >
            </div>
          </div>
          <div class="ml-3" :class="{ marginAdjustMobile: $isMobile }">
            <v-banner
              v-if="
                subscriptionPlan &&
                subscriptionPlan.value === 'FREE' &&
                !trialEnded
              "
              color="warning"
              rounded
              single-line
              elevation="3"
              min-width="260"
              ><span class="primaryB--text">
                <strong
                  >{{ $t("subscriptions.trialPeriodEndingIn") + ": "
                  }}{{
                    daysTillTrialPlanEnds > 1
                      ? daysTillTrialPlanEnds + " " + $t("subscriptions.days")
                      : daysTillTrialPlanEnds + " " + $t("subscriptions.day")
                  }}</strong
                ></span
              ></v-banner
            >
            <v-banner
              v-if="
                subscriptionPlan &&
                subscriptionPlan.value === 'FREE' &&
                trialEnded
              "
              color="negative"
              rounded
              single-line
              elevation="3"
              min-width="260"
              ><span class="primaryB--text">{{
                $t("subscriptions.trialPeriodEnded")
              }}</span></v-banner
            >
            <v-banner
              v-if="noSubscriptionPlanSet"
              color="negative"
              rounded
              single-line
              elevation="3"
              min-width="260"
              ><span class="primaryB--text">{{
                $t("subscriptions.mustSetPlan")
              }}</span></v-banner
            >
          </div>
        </div>
      </div>
      <!-- <div class="mt-4">
        <div class="primaryDark--text font-weight-bold mb-4">
          {{ $t("adminRestaurant.categories") }}:
        </div>
        <div
          v-if="restaurant && restaurant.venueCategories"
          class="restaurant-tags d-flex flex-wrap align-center"
        >
          <span
            class="restaurant-tag"
            v-for="category in restaurant.venueCategories"
            :key="category.id"
          >
            {{ category.name }}
          </span>
        </div>
      </div> -->
    </v-card>
    <div class="d-flex" :class="{ flexDirectionColumnMobile: $isMobile }">
      <assign-supplier-card
        v-if="isSuperAdmin"
        :width="$isMobile ? '100%' : '60%'"
        :allSuppliers="allSuppliers"
        :addedSuppliers="addedSuppliers"
        @onSupplierSelected="handleOnSupplierSelected"
        @onSupplierRemove="handleOnSupplierRemove"
      ></assign-supplier-card>
      <users-card
        :allUsers="allUsers"
        :isSuperAdmin="isSuperAdmin"
        :isAdmin="isAdmin"
        :restaurantName="restaurant && restaurant.name"
        @onMakeAdmin="handleOnMakeAdmin"
        @onRemoveAdmin="handleOnRemoveAdmin"
        @onDisableUser="handleOnDisableUser"
        @onVerify="handleOnVerify"
        :user="user"
        :restaurantId="restaurantID"
        @userInvited="handleUserInvited"
      >
      </users-card>
    </div>
    <div
      v-if="isSuperAdmin"
      class="d-flex"
      :class="{ flexDirectionColumnMobile: $isMobile }"
    >
      <stations-card :width="$isMobile ? '100%' : '43%'"></stations-card>
    </div>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//components
import StatsCard from "./components/StatsCard.vue";
import UsersCard from "./components/UsersCard.vue";
import AssignSupplierCard from "./components/AssignSupplierCard.vue";
import StationsCard from "./components/StationsCard.vue";
import { subscriptionPlans } from "../../shared/subscriptionPlans";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "@/shared/statuses";
//constants
export default {
  name: "AdminRestaurant",
  props: {
    allRestaurants: {},
    allSuppliers: {},
    isSuperAdmin: {},
    isAdmin: {},
    user: {},
  },
  components: { StatsCard, UsersCard, AssignSupplierCard, StationsCard },

  data: () => ({
    navItems: [],
    currentTabSelected: {},
    selectedItem: null,
    addedSuppliers: {},
    subscriptions: [],
    isChangePlan: false,
    newSubscriptionPlan: null,
    loadingSubscriptions: false,
  }),
  created() {
    this.subscriptions = [
      {
        id: 0,
        label: this.$t("subscriptions.free"),
        value: subscriptionPlans.FREE,
      },
      {
        id: 1,
        label: this.$t("subscriptions.basic"),
        value: subscriptionPlans.BASIC,
      },
      {
        id: 2,
        label: this.$t("subscriptions.pro"),
        value: subscriptionPlans.PRO,
      },
    ];
    this.restaurantID = this.$route.params.id;
    this.$store.dispatch("orders/getAllVenueOrders", {
      venueId: this.restaurantID,
    });
    this.$store.dispatch("admin/clearRestaurantData");
    this.loadRestaurantData();

    this.navItems = [
      {
        id: 0,
        name: this.$t("adminDashboard.allRestaurants"),
      },
      { id: 1, name: this.$t("adminDashboard.allUsers") },
    ];
  },
  methods: {
    loadRestaurantData() {
      this.$store.dispatch("loader/setLoadingState");
      this.$store.dispatch("admin/getSingleRestaurant", this.restaurantID).then(
        () => {
          this.$store.dispatch("loader/setLoadingState");
        },
        () => {
          this.$store.dispatch("loader/setLoadingState");
        }
      );
      this.isSuperAdmin &&
        this.$store.dispatch(
          "subscription/getVenueSubscription",
          this.restaurantID
        );
    },
    goBack() {
      this.$router.push({ name: "AdminRestaurants" });
    },
    handleTabSelected(val) {
      this.currentTabSelected = val;
      switch (val.id) {
        case 0:
          this.$router.push("/admin/restaurants");
          break;
        case 1:
          this.$router.push("/admin/users");
          break;
      }
    },

    processRestaurantData() {
      this.addedSuppliers =
        this.restaurant &&
        this.restaurant.suppliers &&
        this.restaurant.suppliers.map((f) => f.id);
    },
    handleOnSupplierSelected(value) {
      this.$store
        .dispatch("admin/linkVenueSupplier", {
          venueID: this.restaurant.id,
          supplierID: value,
        })
        .then(() => {
          this.$store.dispatch("admin/getAllRestaurants");
        });
    },
    handleOnSupplierRemove(supplierId) {
      this.$store
        .dispatch("admin/unLinkVenueSupplier", {
          venueID: this.restaurant.id,
          supplierID: supplierId,
        })
        .then(() => {
          this.$store.dispatch("admin/getAllRestaurants");
        });
    },
    handleOnMakeAdmin(user) {
      this.$store.dispatch("userProfile/makeAdminUser", user.id).then(() => {
        this.$store.dispatch("loader/setLoadingState");
        this.$store
          .dispatch("admin/getSingleRestaurant", this.restaurantID)
          .then(
            () => {
              this.$store.dispatch("loader/setLoadingState");
            },
            () => {
              this.$store.dispatch("loader/setLoadingState");
            }
          );
      });
    },
    handleOnRemoveAdmin(user) {
      this.$store.dispatch("userProfile/removeAdminUser", user.id).then(() => {
        this.$store.dispatch("loader/setLoadingState");
        this.$store
          .dispatch("admin/getSingleRestaurant", this.restaurantID)
          .then(
            () => {
              this.$store.dispatch("loader/setLoadingState");
            },
            () => {
              this.$store.dispatch("loader/setLoadingState");
            }
          );
      });
    },
    handleOnDisableUser(user) {
      this.$store.dispatch("admin/disableUser", user.id).then(() => {
        this.$store.dispatch("loader/setLoadingState");
        this.$store
          .dispatch("admin/getSingleRestaurant", this.restaurantID)
          .then(
            () => {
              this.$store.dispatch("loader/setLoadingState");
            },
            () => {
              this.$store.dispatch("loader/setLoadingState");
            }
          );
      });
    },
    handleOnVerify(user) {
      this.$store
        .dispatch("admin/verifyUserSuperAdmin", {
          userId: user.id,
          venueId: this.restaurantID,
        })
        .then(() => {
          this.$store.dispatch("loader/setLoadingState");
          this.$store
            .dispatch("admin/getSingleRestaurant", this.restaurantID)
            .then(
              () => {
                this.$store.dispatch("loader/setLoadingState");
              },
              () => {
                this.$store.dispatch("loader/setLoadingState");
              }
            );
        });
    },
    handleUserInvited() {
      this.loadRestaurantData();
    },
    onPlanChange(value) {
      this.newSubscriptionPlan = value;
    },
    onChangePlanToggle() {
      this.isChangePlan = !this.isChangePlan;
    },
    onSavePlanConfirm() {
      this.$store.dispatch("subscription/setVenueSubscription", {
        venueId: this.restaurantID,
        plan: this.newSubscriptionPlan,
      });
    },
    onSavePlanCancel() {
      this.isChangePlan = false;
    },
    getPhoneNumber(phoneNumber) {
      if (phoneNumber && phoneNumber[0] !== '0') {
        return `0${phoneNumber}`;
      }
      return phoneNumber;
    }
  },
  computed: {
    ...mapGetters({
      getSingleRestaurant: "admin/getSingleRestaurant",
      getSingleRestaurantStatus: "admin/getSingleRestaurantStatus",
      getAllUsersOfVenue: "admin/getAllUsersOfVenue",
      getAllUsersOfVenueStatus: "admin/getAllUsersOfVenueStatus",
      allOrders: "orders/getAllOrdersVenueState",
      getDaysTillTrialPlanEnds: "subscription/getDaysTillTrialPlanEnds",
      getSettingSubscriptionStatus: "subscription/getSettingSubscriptionStatus",
      getVenueSubscriptionLoadingStatus:
        "subscription/getVenueSubscriptionLoadingStatus",
    }),
    restaurant() {
      return this.getSingleRestaurant;
    },
    allUsers() {
      return this.restaurant && this.restaurant.users;
    },
    numberOfOrders() {
      return this.allOrders && this.allOrders.length;
    },
    getSubscription() {
      return this.$attrs.noSubscriptionSet;
    },
    subscriptionPlan() {
      const plan = this.subscriptions.find((s) => s.value == this.currentPlan);
      return plan;
    },
    daysTillTrialPlanEnds() {
      return this.$attrs.isFreePlan && this.getDaysTillTrialPlanEnds;
    },
    trialEnded() {
      return this.$attrs.isTrialEnd;
    },
    setNewSubscriptionStatus() {
      return this.getSettingSubscriptionStatus;
    },
    isSavePlanChangeDisabled() {
      if (this.subscriptionPlan) {
        return this.newSubscriptionPlan === this.subscriptionPlan.value;
      } else {
        return !this.newSubscriptionPlan;
      }
    },
    currentPlan() {
      return this.$attrs.currentSubscriptionPlan;
    },
    noSubscriptionPlanSet() {
      return this.$attrs.noSubscriptionSet;
    },
    subscriptionsLoadingstatus() {
      return this.getVenueSubscriptionLoadingStatus;
    },
  },
  watch: {
    restaurant() {
      this.restaurant && this.processRestaurantData();
    },
    setNewSubscriptionStatus() {
      if (this.setNewSubscriptionStatus === REQUEST_SUCCESS) {
        window.location.reload();
      }
      if (this.subscriptionsLoadingstatus === REQUEST_PENDING) {
        this.loadingSubscriptions = true;
      } else {
        this.loadingSubscriptions = false;
      }
    },
    subscriptionsLoadingstatus() {
      if (this.subscriptionsLoadingstatus === REQUEST_PENDING) {
        this.loadingSubscriptions = true;
      } else {
        this.loadingSubscriptions = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.restaurant-root {
  padding: 0;
}
.restaurant-info {
  width: 30%;
}
.restaurant-stats {
  width: 50%;
  @media screen and(max-width: 900px) {
    margin-top: 24px;
    width: 100% !important;
    flex-direction: center !important;
  }
}
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
  cursor: pointer;
}
.restaurant-tag {
  padding: 2px 8px;
  border-radius: 8px;
  background: #276ef1;
  color: #ffffff;
  &:not(:first-child) {
    margin-left: 4px;
  }
}
.flexDirectionColumnMobile {
  @media screen and (max-width: 900px) {
    flex-direction: column !important;
  }
}
.width100Mobile {
  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
}
.maxWidth200 {
  max-width: 200px;
  @media screen and (max-width: 600px) {
    max-width: 100% !important;
  }
}
.marginAdjustMobile {
  margin-top: 12px;
  margin-left: 0 !important;
  width: 100%;
}
</style>
