<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">
            {{ $t("adminStations.maximumStationLimitReached") }}
          </span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <div style="text-align: justify">
        <span>{{ $t("adminStations.maximumStationLimitReachedMessage") }}</span>
      </div>
      <div class="d-flex justify-center mt-6">
        <v-btn color="tertiary" @click="closeDialog"
          >{{ $t("sendPlanInquiry.cancel") }}
        </v-btn>
        <v-btn class="ml-3" color="accent" @click="onSend"
          >{{ $t("sendPlanInquiry.send") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "SendNewStationInquiryModal",
  props: {
    dialog: {},
    venueId: {}
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    dialog(val) {
      this.showDialog = val;
    },
  },
  created() {
    this.showDialog = this.dialog;
  },
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
    onSend() {
      this.$store
        .dispatch("subscription/sendAdditionalStationsInquiry", {venueId: this.venueId})
        .then(() => {
          this.$emit("onSuccessSend");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100vh;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100vh;
  }
}
.close-icon {
  cursor: pointer;
}
</style>
