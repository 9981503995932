import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=d059c230&scoped=true&"
import script from "./ProfileMenu.vue?vue&type=script&lang=js&"
export * from "./ProfileMenu.vue?vue&type=script&lang=js&"
import style0 from "./ProfileMenu.vue?vue&type=style&index=0&id=d059c230&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d059c230",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VList,VListItem,VListItemContent,VMenu,VRow})
