<template>
  <nav>
    <!-- app bar (big screens) -->
    <v-app-bar
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-none d-md-block"
      v-if="isAuthenticated"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-img
        style="cursor: pointer"
        @click="goDashboard"
        max-width="122"
        :src="require('../assets/img/NavLogo.png')"
      >
      </v-img>
      <span
        v-if="isRouteAdmin"
        class="text-h6 font-weight-black primaryDark--text mt-1 ml-1"
        >- ADMIN</span
      >
      <v-spacer></v-spacer>
      <span class="text-subtitle-2 mr-5">{{ profile && profile.name }}</span>
      <v-toolbar-items v-if="isAuthenticated" class="hidden-sm-and-down">
        <profile-menu
          :items="translatedNavDropDownOptions"
          :isPendingInvitation="isPendingInvitation"
        ></profile-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-none d-md-block"
    >
      <v-img max-width="122" :src="require('../assets/img/NavLogo.png')">
      </v-img>
    </v-app-bar>
    <!-- END app bar (big screens) -->
    <!-- app bar user mobile -->
    <v-app-bar
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-block d-md-none app-bar-mobile-profile-menu"
      v-if="isAuthenticated"
    >
      <div class="d-flex align-center justify-space-between width100">
        <div class="d-flex align-center">
          <v-img
            @click="goDashboard"
            max-width="122"
            :src="require('../assets/img/NavLogo.png')"
            class="adjust-logo-margin"
          ></v-img>
          <span
            v-if="isRouteAdmin"
            class="text-subtitle-1 font-weight-black primaryDark--text mt-1 ml-1"
            >- ADMIN</span
          >
        </div>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-block d-md-none"
    >
      <v-img max-width="122" :src="require('../assets/img/NavLogo.png')">
      </v-img>
    </v-app-bar>
    <!-- END app bar user mobile -->

    <!-- Start of mobile side menu -->
    <v-navigation-drawer
      app
      v-model="drawer"
      right
      class="drawer-class"
      temporary
    >
      <v-list>
        <v-list-item>
          <v-list-item-content
            class="d-flex align-center justify-center text-subtitle-2 secondaryDark2--text border-bottom"
          >
            {{ isRouteAdmin ? "ADMIN" : profile && profile.name }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="d-flex align-center"
          v-for="item in translatedNavDropDownOptions"
          v-if="item.show"
          :key="item.id"
          link
          :class="[{ 'active-link': isActive(item) }]"
        >
          <v-list-item-content>
            <router-link
              class="profile-nav-link"
              v-if="!item.isLink"
              :to="
                isPendingInvitation && item.path !== '/logout' ? '#' : item.path
              "
              exact-active-class="active-link"
            >
              {{ item.name }}
            </router-link>
            <a
              class="profile-nav-link"
              v-else
              :href="item.path"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ item.name }}
            </a>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-divider class="mt-8 mb-8 ml-6 mr-6"></v-divider> -->
      </v-list>
    </v-navigation-drawer>
    <!-- End of mobile side menu -->
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import ProfileMenu from "./ProfileMenu.vue";
export default {
  name: "TopNav",
  props: {
    isAuthenticated: Boolean,
    isAdmin: {},
    isSuperAdmin: {},
    getUser: {},
    isPendingInvitation: {},
    venueId: {},
  },
  components: { ProfileMenu },

  data() {
    return {
      drawer: false, // Hide mobile side menu by default
      navDropDownOptions: [],
      sidebarImages: [
        require("../assets/img/HomeSideBarIcon.svg"),
        require("../assets/img/NewOrderSideBarIcon.svg"),
        require("../assets/img/CurrentOrderSideBarIcon.svg"),
      ],
      adminRouteURL: `admin/${this.venueId}/restaurant`,
    };
  },
  created() {
    this.adminRouteURL = `admin/${this.venueId}/restaurant`;
    this.setNavDropDownOptions();
  },
  mounted() {},
  methods: {
    logout() {
      // console.log("logout");
    },
    setNavDropDownOptions() {
      this.navDropDownOptions = [
        {
          id: 0,
          translationKey: "navDropDown.home",
          path: "/",
          show: !this.isSuperAdmin,
        },
        // {
        //   id: 1,
        //   translationKey: "navDropDown.admin",
        //   path: this.getAdminRoute,
        //   show: this.isAdmin && !this.isRouteAdmin && this.isWizardComplete,
        // },
        // {
        //   id: 2,
        //   translationKey: this.isAdmin
        //     ? "sidebarLinks.adminRestaurant"
        //     : "sidebarLinks.adminRestaurants",
        //   path: this.isAdmin
        //     ? `/admin/${this.venueId}/restaurant`
        //     : "/admin/restaurants",
        //   show: (this.isAdmin && this.isWizardComplete) || this.isSuperAdmin,
        // },
        {
          id: 3,
          translationKey: "sidebarLinks.adminSuppliers",
          path: "/admin/suppliers",
          show: this.isSuperAdmin && this.$isMobile,
        },
        {
          id: 4,
          translationKey: "navDropDown.userProfile",
          path: "/profile",
          show: !this.isSuperAdmin,
        },
        {
          id: 7,
          translationKey: "sidebarLinks.adminArticles",
          path: `/admin/items`,
          show: this.isSuperAdmin && this.$isMobile,
        },
        {
          id: 8,
          translationKey: "navDropDown.articles",
          path: "/items",
          show: !this.isSuperAdmin,
        },
        {
          id: 9,
          translationKey: "navDropDown.suppliers",
          path: "/suppliers",
          show: !this.isSuperAdmin,
        },
        {
          id: 10,
          translationKey: "navDropDown.help",
          path:
            this.$i18n.locale === "en"
              ? "https://supplyme.tawk.help/en-us"
              : "https://supplyme.tawk.help/",
          show: true,
          isLink: true,
        },
        {
          id: 11,
          translationKey: this.isAdmin
            ? "sidebarLinks.adminRestaurant"
            : "sidebarLinks.adminRestaurants",
          path: this.isAdmin
            ? `/admin/${this.venueId}/restaurant`
            : "/admin/restaurants",
          show: (this.isAdmin && this.isWizardComplete) || this.isSuperAdmin,
        },
        {
          id: 12,
          translationKey: "sidebarLinks.adminStations",
          path: `/admin/${this.venueId}/stations`,
          show: this.isAdmin && this.isWizardComplete,
        },
        {
          id: 13,
          translationKey: "sidebarLinks.adminUsers",
          path: `/admin/users`,
          show:
            (this.isAdmin && this.isWizardComplete) ||
            (this.isSuperAdmin && this.isRouteAdmin),
        },
        {
          id: 14,
          translationKey: "sidebarLinks.adminReports",
          path: `/admin/reports`,
          show: this.isAdmin && this.isWizardComplete,
        },
        {
          id: 15,
          translationKey: "navDropDown.logout",
          path: "/logout",
          show: true,
        },
      ];
    },
    goDashboard() {
      this.$router.push("/");
    },
    updateNavDropDownOptions() {
      this.navDropDownOptions = this.navDropDownOptions.map((option) => ({
        ...option,
        name: this.$t(option.translationKey),
      }));
    },
    isActive(item) {
      return this.$route.path === item.path;
    },
  },
  computed: {
    ...mapGetters({
      userProfile: "userProfile/getUserProfile",
    }),
    isRouteAdmin() {
      return this.$route.path.includes("admin");
    },
    getAdminRoute() {
      return this.adminRouteURL;
    },
    getUserProfileStatus() {
      return this.userProfileStatus;
    },
    profile() {
      return this.userProfile;
    },
    isWizardComplete() {
      return this.userProfile && this.userProfile.finishedOnboarding;
    },
    userProfileLanguage() {
      return this.userProfile && this.userProfile.language;
    },
    translatedNavDropDownOptions() {
      return this.navDropDownOptions.map((option) => ({
        ...option,
        name: this.$t(option.translationKey),
      }));
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    isAdmin() {
      this.$forceUpdate();
    },
    isSuperAdmin() {
      this.$forceUpdate();
    },
    isRouteAdmin() {
      this.setNavDropDownOptions();
      this.updateNavDropDownOptions();
      this.$forceUpdate();
    },
    getUser() {
      this.setNavDropDownOptions();
      this.updateNavDropDownOptions();
      this.$forceUpdate();
    },
    venueId() {
      this.adminRouteURL = `/admin/${this.venueId}/restaurant`;
      this.setNavDropDownOptions();
      this.updateNavDropDownOptions();
      this.$forceUpdate();
    },
    "$i18n.locale": {
      handler() {
        this.setNavDropDownOptions();
        this.updateNavDropDownOptions();
      },
      immediate: true,
    },
    getIsMobile(newVal) {
      if (!newVal) {
        this.drawer = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-bar-box-shadow {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04) !important;
}
.adjust-logo-margin {
}
.icon-margin-adjust {
  margin-top: 18px;
}
.logout-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.text-align-center {
  justify-content: center;
}
.width100 {
  width: 100%;
}
.border-bottom {
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 8px;
}
.profile-nav-link {
  color: #000000;
  text-decoration: none;
}
.active-link {
  background-color: gray;
  color: #fff;
}
.profile-menu-list {
  z-index: 999;
}
</style>
