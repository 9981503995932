import api from "./api";
import {
  LOGIN_URL,
  SIGNUP_URL,
  CHECK_EMAIL_URL,
  REGISTER_VENUE_URL,
  GET_ALL_CATEGORIES_URL,
  requestResetPasswordUrl,
  REQUEST_CHANGE_PASSWORD_URL,
  verifyEmailURL,
  SIGNUP_URL_INVITE,
} from "../shared/endpoints";
import TokenService from "./token.service";
class AuthService {
  login(user) {
    let data = new FormData();
    data.append("username", user.username);
    data.append("password", user.password);
    return api.post(LOGIN_URL, data).then((response) => {
      let { data } = response;
      if (data && data.jwt) {
        // localStorage.setItem("userData", JSON.stringify(data));
        TokenService.setUser(data);
      }
      return data;
    });
  }

  logout() {
    TokenService.removeUser();
  }

  register(user) {
    let data = new FormData();
    data.append("name", user.username);
    data.append("password", user.password);
    data.append("confirm_password", user.passwordRepeat);
    data.append("email", user.email);
    data.append("phone", user.phone);
    data.append("selectedLanguage", user.selectedLanguage);
    return api.post(SIGNUP_URL, data);
  }

  checkEmail(email) {
    let data = new FormData();
    data.append("email", email);
    return api.post(CHECK_EMAIL_URL, data);
  }

  registerVenue(venue) {
    let data = {
      bussiness_name: venue.restaurantBusinessName,
      pib: venue.pib,
      name: venue.restaurantName,
      address: venue.restaurantAddress,
      venueCategories: venue.restaurantGroups,
      phoneNumber: venue.phone,
      email: venue.email,
      supplersWorkingWith: venue.supplersWorkingWith,
    };
    return api.post(REGISTER_VENUE_URL, JSON.stringify(data));
  }

  getAllCategories() {
    return api.get(GET_ALL_CATEGORIES_URL);
  }

  requestPasswordReset(email) {
    return api.post(requestResetPasswordUrl(email));
  }

  requestChangePassword(userData) {
    let data = new FormData();
    data.append("userId", userData.id);
    data.append("token", userData.token);
    data.append("password", userData.password);
    return api.post(REQUEST_CHANGE_PASSWORD_URL, data);
  }

  requestVerifyEmailService(token) {
    return api.get(verifyEmailURL(token));
  }

  registerFromInvite(user) {
    let data = new FormData();
    data.append("name", user.username);
    data.append("password", user.password);
    data.append("confirm_password", user.passwordRepeat);
    data.append("email", user.email);
    data.append("phone", user.phone);
    return api.post(SIGNUP_URL_INVITE, data);
  }
}
export default new AuthService();
