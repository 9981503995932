import SubscriptionService from "../services/subscription.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";
import { subscriptionPlans } from "../shared/subscriptionPlans";

const initialState = {
  subscription: null,
  getSubscriptionLoadingStatus: REQUEST_UNDEFINED,
  setSubscriptionLoadingStatus: REQUEST_UNDEFINED,
  subscriptionError: null,
  sendPlanInquiryLoadingStatus: REQUEST_UNDEFINED,
  sendAdditionalStationsInquiryLoadingStatus: REQUEST_UNDEFINED,
};

export const subscription = {
  namespaced: true,
  state: initialState,
  actions: {
    getVenueSubscription({ commit }, venueId) {
      commit("getSubscriptionPending");
      return SubscriptionService.getSubscription(venueId).then(
        (response) => {
          commit("getSubscriptionSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSubscriptionFailure", error.response);
          return Promise.reject(error);
        }
      );
    },
    setVenueSubscription({ commit }, data) {
      commit("setSubscriptionPending");
      return SubscriptionService.setSubscription(data).then(
        (response) => {
          commit("setSubscriptionSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("setSubscriptionFailure", error.response);
          return Promise.reject(error);
        }
      );
    },
    sendPlanInquiry({ commit }, data) {
      commit("sendPlanInquiryPending");
      return SubscriptionService.sendPlanInquiry(data).then(
        (response) => {
          commit("sendPlanInquirySuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("sendPlanInquiryFailure", error.response);
          return Promise.reject(error);
        }
      );
    },
    sendAdditionalStationsInquiry({ commit }, data) {
      commit("sendAdditionalStationsInquiryPending");
      return SubscriptionService.sendAdditionalStationsInquiry(data).then(
        (response) => {
          commit("sendAdditionalStationsInquirySuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("sendAdditionalStationsInquiryFailure", error.response);
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getSubscriptionPending(state) {
      state.getSubscriptionLoadingStatus = REQUEST_PENDING;
    },
    getSubscriptionSuccess(state, payload) {
      state.getSubscriptionLoadingStatus = REQUEST_SUCCESS;
      state.subscription = payload;
    },
    getSubscriptionFailure(state) {
      state.getSubscriptionLoadingStatus = REQUEST_FAILURE;
    },

    setSubscriptionPending(state) {
      state.setSubscriptionLoadingStatus = REQUEST_PENDING;
    },
    setSubscriptionSuccess(state) {
      state.setSubscriptionLoadingStatus = REQUEST_SUCCESS;
    },
    setSubscriptionFailure(state) {
      state.setSubscriptionLoadingStatus = REQUEST_FAILURE;
    },

    sendPlanInquiryPending(state) {
      state.sendPlanInquiryLoadingStatus = REQUEST_PENDING;
    },
    sendPlanInquirySuccess(state) {
      state.sendPlanInquiryLoadingStatus = REQUEST_SUCCESS;
    },
    sendPlanInquiryFailure(state) {
      state.sendPlanInquiryLoadingStatus = REQUEST_FAILURE;
    },
    sendAdditionalStationsInquiryPending(state) {
      state.sendAdditionalStationsInquiryLoadingStatus = REQUEST_PENDING;
    },
    sendAdditionalStationsInquirySuccess(state) {
      state.sendAdditionalStationsInquiryLoadingStatus = REQUEST_SUCCESS;
    },
    sendAdditionalStationsInquiryFailure(state) {
      state.sendAdditionalStationsInquiryLoadingStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getVenueSubscription: (state) => {
      return state.subscription;
    },
    getVenueSubscriptionLoadingStatus: (state) => {
      return state.getSubscriptionLoadingStatus;
    },
    trialPlanEnded: (state) => {
      return state.subscription?.trialEnded;
    },
    getVenueSubscriptionPlan: (state) => {
      return state.subscription?.plan;
    },
    getDaysTillTrialPlanEnds: (state) => {
      return state.subscription?.daysTillTrialEnds;
    },
    getSettingSubscriptionStatus: (state) => {
      return state.setSubscriptionLoadingStatus;
    },
    isFreePlan: (state) => {
      return state.subscription?.plan === subscriptionPlans.FREE;
    },
    isBasicPlan: (state) => {
      return state.subscription?.plan === subscriptionPlans.BASIC;
    },
    isProPlan: (state) => {
      return state.subscription?.plan === subscriptionPlans.PRO;
    },
    getSendPlanInquiryStatus: (state) => {
      return state.sendPlanInquiryLoadingStatus;
    },
    getDepartmentLimit: (state) => {
      return state.subscription?.departmentLimit;
    }
  },
};
