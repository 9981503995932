<template>
  <div class="admin">
    <router-view
      :allRestaurants="allRestaurants"
      :allSuppliers="allSuppliers"
      :allUsers="allUsers"
      :isSuperAdmin="isSuperAdmin"
      :isAdmin="isAdmin"
      :venue="venue"
      :user="getUser"
      :isFreePlan="isFreePlan"
      :isBasicPlan="isBasicPlan"
      :isProPlan="isProPlan"
      :isTrialEnd="isTrialEnd"
      :noSubscriptionSet="noSubscriptionSet"
      :currentSubscriptionPlan="currentSubscriptionPlan"
    ></router-view>
  </div>
</template>
<script>
// vuex
import { mapGetters } from "vuex";
export default {
  name: "AdminDashboard",
  components: {},
  props: {
    isSuperAdmin: {},
    isAdmin: {},
    venue: {},
    isFreePlan: {},
    isBasicPlan: {},
    isProPlan: {},
    isTrialEnd: {},
    noSubscriptionSet: {},
    currentSubscriptionPlan: {},
  },
  data: () => ({
    navItems: [],
    currentTabSelected: {},
    selectedCard: "",
  }),
  created() {
    this.isSuperAdmin && this.$store.dispatch("admin/getAllRestaurants");
    this.isSuperAdmin && this.$store.dispatch("admin/getSuppliers");
    this.$store.dispatch("userProfile/getUserProfile");
  },
  mounted() {
    this.setSelectedCard();
  },
  methods: {
    goToPage(pageValue) {
      switch (pageValue) {
        case "restaurants":
          this.$router.push({ name: "AdminRestaurants" });
          this.selectedCard = "restaurants";
          break;
        case "users":
          this.$router.push({ name: "AdminUsers" });
          this.selectedCard = "users";
          break;
        case "suppliers":
          this.$router.push({ name: "AdminSuppliers" });
          this.selectedCard = "suppliers";
          break;
      }
    },
    setSelectedCard() {
      this.selectedCard = this.$route.path.includes("restaurant")
        ? "restaurants"
        : this.$route.path.includes("users")
        ? "users"
        : "suppliers";
    },
  },
  computed: {
    ...mapGetters({
      getAllRestaurants: "admin/getAllRestaurants",
      getAllSuppliers: "admin/getAllSuppliers",
      getAllUsers: "admin/getAllUsers",
      user: "auth/getUser",
    }),
    allRestaurants() {
      return this.getAllRestaurants;
    },
    allSuppliers() {
      return this.getAllSuppliers;
    },
    allUsers() {
      return this.getAllUsers;
    },
    getSelectedCard() {
      return this.selectedCard;
    },
    getUser() {
      return this.user;
    },
  },
};
</script>
<style scoped lang="scss">
.admin {
  padding: 0 32px 32px 32px !important;
  // margin-top: 32px;
  @media screen and(max-width: 600px) {
    padding: 16px !important;
  }
}
</style>
