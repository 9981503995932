<template>
  <div class="padding-adjust current-orders-container">
    <div class="mobile-centering">
      <span class="text-h5 primaryA--text font-weight-bold">{{ $t("currentOrders.currentOrders") }} -
        {{ currentStationName && currentStationName.name }}</span>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CurrentOrdersContainer",
  data: () => ({
    stationID: null,
    selectedTab: 0,
  }),
  computed: {
    ...mapGetters({ allStations: "userProfile/getStations" }),
    stations() {
      return this.allStations;
    },
    currentStationName() {
      return this.stations?.find((s) => s.id === this.stationID);
    },
  },
  created() {
    this.$store.dispatch("userProfile/getDepartments");
    this.stationID = this.$route.params?.departmentID;
  },
  mounted() {
    this.selectedTab = this.$route.path.includes("custom-orders") ? 1 : 0;
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.create-button {
  text-transform: none !important;
}
.button-plus {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #276ef1;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 1px 1px 11px 1px #276ef1;
  }
}
.mobile-centering {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
}

.tab-height-adjust {
  height: fit-content;
}
</style>
