<template>
  <div class="padding-adjust">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("articles.listArticles")
      }}</span>
      <div class="d-flex flex-column align-center">
        <v-btn
          v-if="isUserAdmin"
          dense
          filled
          color="primary"
          class="ml-2 mt-2"
          @click="onCreateArticle"
          >{{ $t("unverifiedCreateArticle.createArticles") }}</v-btn
        >
        <v-btn
          v-if="showBackToNewOrder"
          color="accent"
          small
          @click="backToNewOrder"
          >{{ $t("articles.backToOrder") }}</v-btn
        >
      </div>
    </div>
    <div class="search-filter">
      <v-text-field
        :disabled="!selectedFilterSupplier"
        id="search"
        name="search"
        class="search-input"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchValue"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
      ></v-text-field>
      <div class="d-flex selects-wrap">
        <v-select
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentFilter"
          item-text="name"
          item-value="id"
          solo
          @change="onDepartmentSelected"
          hide-details="true"
        ></v-select>
        <v-select
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedFilterSupplier"
          item-text="name"
          item-value="id"
          solo
          @change="onSupplierSelected"
          :disabled="!selectedDepartmentFilter"
          hide-details="true"
        ></v-select>
        <v-select
          :disabled="!selectedFilterSupplier"
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="selectedFilterGroup"
          item-text="category"
          item-value="id"
          solo
          @change="onGroupSelected"
          hide-details="true"
        ></v-select>
      </div>
      <v-checkbox
        class="favorite-checkbox ml-2 mt-2"
        :label="$t('articles.favoriteOnly')"
        v-model="isFavoriteFilterSelected"
        :disabled="!selectedFilterSupplier || !selectedDepartmentFilter"
      ></v-checkbox>
    </div>

    <div :class="[$isMobile ? 'mt-0' : 'mt-11']">
      <articles-data-table
        v-if="!noArticles && !$isMobile"
        :headers="headers"
        class="elevation-0"
        :hide-default-footer="false"
        :data="getAllArticles"
        :isDataLoading="isDataLoading"
        @onArticlePriceUpdate="handleArticlePriceUpdated"
        :totalCount="getTotalCount"
        @onPageChange="handlePageChange"
        :pageSize="pageSize"
        @onArticleFavorite="handleOnFavoriteArticle"
        @itemsPerPageChange="onItemsPerPageChange"
        :departmentId="selectedDepartmentFilter"
        @onEditArticle="handleOnEditArticle"
        @onArticleDeleteAction="handleOnArticleDeleteAction"
      ></articles-data-table>
      <div v-else-if="$isMobile && !noArticles">
        <hr class="horizontal-line" />
        <div class="article-item-wrapper" @scroll="handleScroll">
          <article-item
            v-for="(article, index) in getAllArticles"
            :key="index"
            :article="article"
            @onArticleFavorite="handleOnFavoriteArticle"
            :departmentId="selectedDepartmentFilter"
            @onEditArticle="handleOnEditArticle"
            @onArticleDeleteAction="handleOnArticleDeleteAction"
          ></article-item>
        </div>
      </div>
      <no-results-content
        v-else
        :noResultsText="$t('common.noResuls')"
        :page="'articles'"
        :isFavoriteOnlySearch="isFavoriteFilterSelected && !searchValue"
        :supplierId="selectedFilterSupplier"
        :departmentId="selectedDepartmentFilter"
        :requestedArticleName="searchValue"
        :isSearchInitiated="getIsSearchedInitiated"
        @onSuccessRequestSent="handleOnSuccessRequestSent"
      ></no-results-content>
    </div>
    <create-article-unverified-modal
      v-if="showCreateUnverifiedArticleModal"
      :dialog="showCreateUnverifiedArticleModal"
      @onClose="handleOnCloseUnverifiedArticleModal"
      :supplierId="selectedFilterSupplier"
    ></create-article-unverified-modal>
    <edit-unverified-article-modal
      v-if="showEditArticleModal"
      :dialog="showEditArticleModal"
      :editArticleData="articleToEdit"
      :departmentId="selectedDepartmentFilter"
      @onClose="handleOnCloseEditArticleModal"
      :selectedSupplier="selectedFilterSupplier"
      @onSuccessUpdatedArticle="handleOnSuccessUpdateArticle"
    ></edit-unverified-article-modal>
    <simple-warning-modal
      v-if="showDeleteArticleModal"
      :dialog="showDeleteArticleModal"
      @onClose="showDeleteArticleModal = false"
      @onConfirm="onConfirmDeleteArticle"
      @onCancel="showDeleteArticleModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </div>
</template>
<script>
import NoResultsContent from "../../components/NoResultsContent.vue";
import ArticlesDataTable from "./ArticlesDataTable.vue";
import CreateArticleUnverifiedModal from "./modals/CreateArticleUnverifiedModal.vue";
import EditUnverifiedArticleModal from "./modals/EditUnverifiedArticleModal.vue";
import SimpleWarningModal from "../../components/SimpleWarningModal.vue";
import ArticleItem from "./components/ArticleItem.vue";
//vuex
import { mapGetters } from "vuex";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../shared/statuses";
//Event bus
import EventBus from "../../shared/EventBus";
import { debounce } from "lodash";

export default {
  name: "ArticlesPage",
  components: {
    NoResultsContent,
    ArticlesDataTable,
    CreateArticleUnverifiedModal,
    EditUnverifiedArticleModal,
    SimpleWarningModal,
    ArticleItem,
  },
  data() {
    return {
      fab: false,
      top: true,
      left: true,
      headers: [],
      articlesData: [],
      isDataLoading: false,
      selectedArticles: [],
      searchValue: "",
      selectedFilterGroup: "",
      selectedFilterSupplier: "",
      selectedDepartmentFilter: "",
      showImportArticleModal: false,
      initialArticles: {},
      typingTimer: null,
      doneTypingInterval: 1500,
      pageNumber: 1,
      pageSize: 10,
      isFavoriteFilterSelected: false,
      showCreateUnverifiedArticleModal: false,
      articleToDelete: null,
      showDeleteArticleModal: false,
      showEditArticleModal: false,
      articleToEdit: null,
      warningModalTitle: this.$t(
        "adminStations.deleteArticleWarningModalMessage"
      ),
      isScrolling: false,
      scrollTimeout: null,
      isLoadingMore: false,
      debouncedHandleScroll: {},
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.$analytics.logEvent("page_view", { page_title: "Articles Page" });
    this.debouncedHandleScroll = debounce(this.handleScroll, 200);
    this.$store.dispatch("articles/clearAllArticles");
    this.$store.dispatch("articles/clearAllArticlesSupplier");
  },
  created() {
    this.$store.dispatch("userProfile/getUserProfile");
    // .then(() => {
    //   this.$store.dispatch("userProfile/getVenueSuppliers", {
    //     venueId: this.getVenue.id,
    //   });
    // });
    this.$store.dispatch("userProfile/getDepartments");

    this.headers = [
      {
        text: "articles.favorite",
        value: "checkbox_action",
        sortable: false,
      },
      {
        text: "articles.recommendedQuantity",
        value: "recommendedQuantity",
        sortable: false,
      },
      {
        text: "common.name",
        value: "name",
        sortable: false,
      },
      {
        text: "common.group",
        value: "__category__",
        sortable: false,
      },

      // temporarily disabled on client
      // {
      //   text: this.$t("articles.price"),
      //   value: "defaultPrice",
      //   sortable: false,
      // },
    ];
    if (this.isUserAdmin) {
      this.headers.push({
        text: "common.actions",
        value: "article_action",
        sortable: false,
      });
    }
  },
  beforeDestroy() {
    // Clear the timeout when the component is destroyed
    if (this.scrollTimeout !== null) {
      clearTimeout(this.scrollTimeout);
    }
  },
  methods: {
    handleScroll(event) {
      const element = event.target;

      if (!this.isScrolling) {
        this.isScrolling = true;
      }

      if (this.scrollTimeout !== null) {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
        this.scrollTimeout = null;
      }, 500);

      const scrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;
      if (
        scrollBottom <= 0 &&
        !this.isLoadingMore &&
        this.getTotalCount > this.getAllArticles.length
      ) {
        this.loadMoreArticles();
      }
    },
    onConfirmDeleteArticle() {
      this.$store
        .dispatch("unverifiedSupplier/deleteSupplierArticle", {
          supplierId: this.selectedFilterSupplier,
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteArticleModal = false;
          this.articleToDelete = null;

          this.$store
            .dispatch("articles/getAllArticleSupplierCategories", {
              supplierId: this.selectedFilterSupplier,
            })
            .then((response) => {
              this.selectedFilterGroup = response && response.data[0].id;
              this.getAllArticlesSupplier(false);
            });
        });
    },
    handleOnSuccessUpdateArticle() {
      this.articleToEdit = null;
      this.showEditArticleModal = false;
      this.$store
        .dispatch("articles/getAllArticleSupplierCategories", {
          supplierId: this.selectedFilterSupplier,
        })
        .then((response) => {
          this.selectedFilterGroup = response && response.data[0].id;
          this.getAllArticlesSupplier(false);
        });
    },
    handleOnCloseEditArticleModal() {
      this.articleToEdit = null;
      this.showEditArticleModal = false;
    },
    handleOnArticleDeleteAction(article) {
      this.articleToDelete = article;
      this.showDeleteArticleModal = true;
    },
    handleOnEditArticle(article) {
      this.articleToEdit = article;
      this.showEditArticleModal = true;
    },
    handleImportArticles() {
      this.showImportArticleModal = true;
    },
    handleSelectedArticles(article) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: article.id,
          defaultPrice: article.defaultPrice,
          enabled: article.enabled,
        })
        .then(
          () => {},
          (error) => {
            console.log("article error", error);
          }
        );
    },
    handleCancel() {
      this.selectedArticles = [];
      this.articlesData.forEach((a) => {
        this.$set(a, "selected", false);
      });
      this.$forceUpdate();
    },
    onSupplierSelected(e) {
      this.selectedFilterGroup = null;
      this.$store
        .dispatch("articles/getAllArticleSupplierCategories", {
          supplierId: e,
        })
        .then((response) => {
          this.selectedFilterGroup = response && response.data[0].id;
          this.getAllArticlesSupplier(false);
        });
    },
    backToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      this.$router.push(`/${data.id}/new-order`);
      localStorage.removeItem("cameFromNewOrder");
    },
    handleArticlePriceUpdated(value) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: value.item.id,
          defaultPrice: value.newPrice,
          enabled: value.item.enabled,
        })
        .then(
          () => {
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.articleUpdated"),
            });
          },
          (error) => {
            console.log("article update error", error);
          }
        );
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier(false);
    },
    getAllArticlesSupplier(isAccumulating) {
      !isAccumulating &&
        this.$store.dispatch("articles/clearAllArticlesSupplier");
      let payload = {
        departmentId: this.selectedDepartmentFilter,
        supplierId: this.selectedFilterSupplier,
        pageNum: this.pageNumber,
        categoryId: this.selectedFilterGroup,
        searchQuery: this.searchValue,
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
        isAccumulating,
      };
      !this.selectedFilterGroup && delete payload.categoryId;
      this.$store.dispatch("articles/getArticlesSupplier", payload).then(
        () => {
          this.isLoadingMore = false;
        },
        (error) => {
          console.log("error", error);
        }
      );
    },
    onGroupSelected() {
      this.pageNumber = 1;
      this.getAllArticlesSupplier(false);
    },
    handlePageChange(page) {
      this.pageNumber = page;
      this.getAllArticlesSupplier(false);
    },
    onDepartmentSelected(departmentId) {
      this.selectedFilterSupplier = "";
      this.selectedFilterGroup = null;
      this.$store.dispatch("articles/clearAllArticlesSupplier");
      this.$store.dispatch("supplier/getSuppliersForDepartment", {
        departmentId,
      });
    },
    handleOnFavoriteArticle(item) {
      this.$store
        .dispatch("articles/toggleFavoriteArticle", {
          departmentId: this.selectedDepartmentFilter,
          articleId: item.id,
        })
        .then(
          () => {
            // this.getAllArticlesSupplier();
          },
          (err) => {
            alert(err);
          }
        );
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.getAllArticlesSupplier(false);
    },
    handleOnSuccessRequestSent() {
      this.searchValue = "";
      this.searchArticles();
    },
    onCreateArticle() {
      // show modal for creating articles
      this.showCreateUnverifiedArticleModal = true;
    },
    handleOnCloseUnverifiedArticleModal() {
      this.showCreateUnverifiedArticleModal = false;
      if(this.selectedFilterSupplier && this.selectedDepartmentFilter) {
        this.getAllArticlesSupplier(false);
      }
    },
    loadMoreArticles() {
      this.isLoadingMore = true;
      this.pageNumber += 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier(true);
    },
  },
  watch: {
    getAllProductsStatus() {
      this.isDataLoading = this.getAllProductsStatus === REQUEST_PENDING;
    },
    isFavoriteFilterSelected() {
      this.getAllArticlesSupplier(false);
    },
  },
  computed: {
    ...mapGetters({
      allSuppliers: "supplier/getDepartmentSuppliers",
      getAllProducts: "articles/getAllSupplierArticles",
      getAllProductsStatus: "articles/getAllSupplierArticlesStatus",
      getAllArticlesCategories: "articles/getAllArticlesCategories",
      getTotalArticlesCount: "articles/getTotalArticlesCount",
      getStations: "userProfile/getStations",
      getVenue: "userProfile/getVenue",
      isUserAdmin: "auth/isUserAdmin",
    }),
    getAllArticles() {
      return this.getAllProducts;
    },
    getAllSuppliers() {
      return this.allSuppliers
        ?.filter((supplier) => supplier.id)
        ?.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
    },
    getAllArticlesStatus() {
      return this.getAllProductsStatus;
    },
    noArticles() {
      return this.getAllArticles.length === 0 && this.isDataLoading === false;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getNumArticles() {
      return this.selectedArticles.length;
    },
    showBackToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      return data && data.show;
    },
    allCategories() {
      let ctgs = this.getAllArticlesCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    getTotalCount() {
      return this.getTotalArticlesCount;
    },
    getAllDepartments() {
      return this.getStations;
    },
    getIsSearchedInitiated() {
      return this.searchValue && this.getAllProductsStatus === REQUEST_SUCCESS;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-fix {
  text-transform: unset !important;
}
.bottom-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.04);
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 100px;
}
.add-button {
  min-height: 54px;
  width: 189px;
  text-transform: none;
}
.cancel-button {
  min-height: 54px;
  width: 147px;
  margin-left: 16px;
  text-transform: none;
  color: #a6a6a6;
}
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    justify-content: space-between;
    max-width: 33%;
  }
}
.selects-wrap {
  @media screen and (max-width: 575px) {
    justify-content: space-between;
  }
}
.position-fixed {
  position: fixed;
  top: 50%;
  left: 0;
}
.float-add-btn {
  width: 36px !important;
  border-radius: unset !important;
  border-top-right-radius: 30% !important;
  border-bottom-right-radius: 30% !important;
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
    margin-top: 8px;
  }
}

.article-item-wrapper {
  max-height: calc(100vh - 420px);
  overflow-y: auto;
  overflow-x: hidden;
}
.favorite-checkbox {
  @media screen and (max-width: 575px) {
    margin-left: 0 !important;
  }
}
.horizontal-line {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
