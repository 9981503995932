<template>
  <div class="pr-2 pb-6 pl-2">
    <articles-page></articles-page>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArticlesPage from "./ArticlesPage.vue";

export default {
  name: "ArticlesDashboard",
  components: { ArticlesPage },
  props: {},
  data: () => ({
    selectedTab: "articles",
  }),
  mounted() {},
  created() {},
  methods: {
    tabSelected(event) {
      this.selectedTab = event === 0 ? "articles" : "market";
    },
  },
  computed: {
    ...mapGetters({
      basicPlan: "subscription/isBasicPlan",
    }),
    isBasicPlan() {
      return this.basicPlan;
    },
  },
};
</script>
<style scoped lang="scss">
.item-text {
  text-transform: none !important;
}
</style>
