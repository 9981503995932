import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import VueI18n from "vue-i18n";
import i18n from "./i18n";
import "./assets/global.css";
import setupInterceptors from "./services/setupInterceptors";
import { isMobile } from "mobile-device-detect";
import VueCookie from "vue-cookie";

Vue.use(VueCookie);
Vue.config.productionTip = false;
setupInterceptors(store);

const moment = require("moment");
require("moment/locale/sr");

Vue.prototype.$isMobile = isMobile;

Vue.use(require("vue-moment"), {
  moment,
});



if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js").then(
      (registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      (err) => {
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

const savedVersion = localStorage.getItem('appVersion') || 'unknown';
Vue.prototype.$appVersion = savedVersion;

fetch("/version.json", { cache: "no-store" })
  .then((res) => res.json())
  .then((data) => {
    Vue.prototype.$appVersion = data.version;
    localStorage.setItem('appVersion', data.version);
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((err) => {
    Vue.prototype.$appVersion = 'unknown';
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
    console.error("Error fetching version.json:", err);
  });
