import {
  getSubscriptionURL,
  SET_SUBSCRIPTION_PLAN,
  SEND_PLAN_INQUIRY_URL,
  SEND_ADDITIONAL_STATION_INQUIRY_URL,
} from "../shared/endpoints";
import api from "./api";

class SubscriptionService {
  getSubscription(venueId) {
    return api.get(getSubscriptionURL(venueId));
  }
  setSubscription(data) {
    const formData = new FormData();
    formData.append("venueId", data.venueId);
    formData.append("plan", data.plan);
    return api.post(SET_SUBSCRIPTION_PLAN, formData);
  }
  sendPlanInquiry(data) {
    const formData = new FormData();
    formData.append("venueId", data.venueId);
    formData.append("userId", data.userId);
    formData.append("selectedPlan", data.selectedPlan);
    formData.append("checkedStations", JSON.stringify(data.checkedStations));
    return api.post(SEND_PLAN_INQUIRY_URL, data);
  }
  sendAdditionalStationsInquiry(data) {
    return api.post(SEND_ADDITIONAL_STATION_INQUIRY_URL, JSON.stringify(data));
  }
}

export default new SubscriptionService();
